<template>
	<div class="ele-body">
		<el-tabs v-model="activeName">
			<el-tab-pane label="活动列表" name="first">
				<el-card shadow="never">
					<!-- 搜索表单 -->
					<div class="d-flexspabet">
						<div>
							<el-button @click="dialogFormVisibleAddReturnActivity=true"
								v-if="permission.includes('sys:return_money:add')" class="addbtn ">添加邀请返现活动</el-button>
						</div>
						<el-form :model="table.where" class="ele-form-search d-flex"
							@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
							<el-form-item label="省:" label-width="35px" class="w-170">
								<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
									placeholder='请选择省' clearable>
									<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
										:label="option.name"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="市:" label-width="35px" class="w-150">
								<el-select v-model="table.where.cid"
									@change="handleChangeCity(table.where.cid),$forceUpdate()" placeholder='请选择市' clearable>
									<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
										:label="option.name"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="县/区:" label-width="62px" class="w-200">
								<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
									<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
										:label="option.name"></el-option>
								</el-select>
							</el-form-item>

							<el-form-item>
								<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
									class="ele-btn-icon search-margin-left">搜索</el-button>
								<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
							</el-form-item>
						</el-form>
					</div>
					<!-- 数据表格 -->
					<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
						:stripe=true highlight-current-row>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="序号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="set_area" label="地区" show-overflow-tooltip min-width="220" />
							<el-table-column prop="m_type" label="商户类型" show-overflow-tooltip min-width="120" >
								<template slot-scope="{row}">
									{{ row.m_type == 1 ? '司机':(row.m_type == 4 ? '跑腿':(row.m_type == 3 ? '超市':(row.m_type == 5 ? '叫餐':'无'))) }}
								</template>
							</el-table-column>
							<el-table-column prop="money" label="活动金额(元)" show-overflow-tooltip min-width="120" />
							<el-table-column prop="l_money" label="剩余活动金额(元)" show-overflow-tooltip min-width="140" />
							<el-table-column prop="l_money" label="退还金额(元)" show-overflow-tooltip min-width="120" />
							<el-table-column prop="user_money" label="邀请用户获得佣金(元)" show-overflow-tooltip
								min-width="160" />
							<el-table-column prop="store_money" label="邀请商户获得佣金(元)" show-overflow-tooltip
								min-width="160" />
							<el-table-column prop="driver_money" label="邀请司机获得佣金(元)" show-overflow-tooltip
								min-width="160" />
							<el-table-column prop="startState" label="开始时间" show-overflow-tooltip min-width="160">
								<template slot-scope="{row}">{{ row.start_time*1000 | toDateString }}</template>
							</el-table-column>
							<el-table-column prop="startState" label="结束时间" show-overflow-tooltip min-width="160">
								<template slot-scope="{row}">{{ row.end_time*1000 | toDateString }}</template>
							</el-table-column>
							<el-table-column prop="state" label="状态" show-overflow-tooltip min-width="160">
								<template slot-scope="{row}">
									{{ row.zt == 0 ? '暂停':(row.zt == 1 ? '正常':(row.zt == 2 ? '关闭':(row.zt == 4 ? '待开启':'已结束'))) }}
								</template>
							</el-table-column>
							<el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link slot="reference" @click="handleWatch(row)" icon="el-icon-view"
										type="primary" :underline="false"
										v-if="permission.includes('sys:return_money:open')">
										查看
									</el-link>
									<span v-if="row.zt == 0" style="margin-left: 10px;">
										<el-link slot="reference" @click="switch_start(row)" type="primary" icon="el-icon-open"
											:underline="false" v-if="permission.includes('sys:return_money:close')">
											开启
										</el-link>
										<el-link slot="reference" @click="stop_start(row)" type="danger" icon="el-icon-circle-close"
											:underline="false" v-if="permission.includes('sys:return_money:close')">
											关闭
										</el-link>
									</span>
									<span v-if="row.zt == 1" style="margin-left: 10px;">
										<el-link slot="reference" @click="pause_start(row)" type="danger" icon="el-icon-video-pause"
											:underline="false" v-if="permission.includes('sys:return_money:close')">
											暂停
										</el-link>
										<el-link slot="reference" @click="stop_start(row)" type="danger" icon="el-icon-circle-close"
											:underline="false" v-if="permission.includes('sys:return_money:close')">
											关闭
										</el-link>
									</span>
									<span v-if="row.zt == 4" style="margin-left: 10px;">
										<el-link slot="reference" @click="switch_start(row)" type="primary" icon="el-icon-open"
											:underline="false" v-if="permission.includes('sys:return_money:open')">
											开启
										</el-link>
									</span>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
					<!-- 对话框：查看 -->
					<el-dialog v-dialogDrag title="查看活动详情" :visible.sync="dialogFormVisibleAddReturnList" @closed="editList={}" :destroy-on-close="true" :lock-scroll="false">
						<el-form :model="editList" label-width="180px" class="edit">
							<el-card shadow="never">
								<el-form-item label="地区：">
									{{editList.set_area}}
								</el-form-item>
								<el-form-item label="活动金额(元)：">
									{{editList.money}}
								</el-form-item>
								<el-form-item label="剩余活动金额(元)：">
									{{editList.l_money}}
								</el-form-item>
								<el-form-item label="退还金额(元)：">
									{{editList.l_money}}
								</el-form-item>
								<!-- <el-form-item label="退还金额(元)：">
									{{editList.l_money}}
								</el-form-item>
								<el-form-item label="退还金额(元)：">
									{{editList.l_money}}
								</el-form-item> -->
								<el-form-item label="邀请用户获得佣金(元)：">
									{{editList.user_money}}
								</el-form-item>
								<el-form-item label="邀请商户获得佣金(元)：">
									{{editList.store_money}}
								</el-form-item>
								<el-form-item label="邀请司机获得佣金(元)：">
									{{editList.driver_money}}
								</el-form-item>
								<el-form-item label="邀请人数：" v-if="editList.m_type==3||editList.m_type==5" prop="c_member_num">
										{{editList.c_member_num}}
									</el-form-item>
									<el-form-item label="免手续费订单数：" v-if="editList.m_type==3||editList.m_type==5" prop="c_order_num">
										{{editList.c_order_num}}
									</el-form-item> 
								<el-form-item label="开始时间：">
									{{editList.start_time*1000 | toDateString }}
								</el-form-item>
								<el-form-item label="结束时间：">
									{{editList.end_time*1000 | toDateString }}
								</el-form-item>
								<el-form-item label="活动海报：">
									<el-image style="width:100px;height:100px;margin-right:10px"
										:src="editList.invite_img" fit="cover"
										:preview-src-list="[editList.invite_img]"></el-image>
								</el-form-item>
							</el-card>
						</el-form>

					</el-dialog>
					<!-- 对话框：添加 -->
					<el-dialog v-dialogDrag title="添加邀请返现活动" :visible.sync="dialogFormVisibleAddReturnActivity" width="1500px" @closed="editForm={}" :destroy-on-close="true" :lock-scroll="false">
						<div style="display: flex;justify-content: space-between;background: white;">
							<el-card shadow="never">
								<el-form :model="editForm" ref="editForm" label-width="180px" style="width:500px" :rules="editRules">
									<el-form-item label="请选择地区：" prop="pid">
										<el-select v-model="editForm.pid" @change="handleChangeProv(editForm.pid)"
											class="selectStyle mb-20" clearable placeholder="请选择省">
											<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
												:label="option.name"></el-option>
										</el-select>
										<el-select v-model="editForm.cid" @change="handleChangeCity(editForm.cid)"
											class="selectStyle mb-20" clearable placeholder="请选择市">
											<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
												:label="option.name"></el-option>
										</el-select>
										<el-select v-model="editForm.aid" @change="$forceUpdate()"
											class="selectStyle" clearable placeholder="请选择区/县">
											<el-option v-for="option in districtArr " :value="option.aid"
												:key="option.aid" :label="option.name"></el-option>
										</el-select>
									</el-form-item>
									<!-- <el-form-item label="所属APP：" prop="atype" style="height: 0px;overflow: hidden;margin-bottom: 0;">
										<el-select v-model="editForm.atype" placeholder="请选择" clearable>
											<el-option label="咱县打车" :value="1" />
											<el-option label="可蚁点" :value="2" />
										</el-select>
									</el-form-item> -->
									<el-form-item label="商户类型：" prop="m_type">
										<el-select v-model="editForm.m_type" placeholder="请选择" clearable>
											<el-option label="司机" :value="1" />
											<el-option label="跑腿" :value="4" />
											<el-option label="超市" :value="3" />
											<el-option label="叫餐" :value="5" />
										</el-select>
									</el-form-item>
									<el-form-item label="邀请人数：" v-if="editForm.m_type==3||editForm.m_type==5" prop="c_member_num">
										<el-input class="input216" v-model="editForm.c_member_num" placeholder="" clearable />
									</el-form-item>
									<el-form-item label="免手续费订单数：" v-if="editForm.m_type==3||editForm.m_type==5" prop="c_order_num">
										<el-input class="input216" v-model="editForm.c_order_num" placeholder="" clearable />
										<span style="color:#ff0000;">（以每月最新订单进行免除）</span>
									</el-form-item> 
									<el-form-item label="活动金额：" v-if="editForm.m_type==1||editForm.m_type==4" prop="money">
										<el-input v-model="editForm.money" autocomplete="off" placeholder="请填活动金额" class="input216"></el-input>
									</el-form-item>
									<el-form-item label="邀请用户获得佣金：" v-if="editForm.m_type==1||editForm.m_type==4"
										prop="user_money">
										<el-input v-model="editForm.user_money" autocomplete="off" placeholder="请填佣金金额" class="input216"></el-input>
									</el-form-item>
									<el-form-item label="邀请商户获得佣金：" v-if="editForm.m_type==1||editForm.m_type==4"
										prop="store_money">
										<el-input v-model="editForm.store_money" autocomplete="off" placeholder="请填佣金金额" class="input216"></el-input>
									</el-form-item>
									<el-form-item label="邀请司机获得佣金：" v-if="editForm.m_type==1||editForm.m_type==4"
										prop="driver_money">
										<el-input v-model="editForm.driver_money" autocomplete="off" placeholder="请填佣金金额" class="input216"></el-input>
									</el-form-item>
									<el-form-item label="开始时间：" prop="start_time">
										<el-date-picker v-model="editForm.start_time" type="datetime" placeholder="请选择开始时间">
										</el-date-picker>
									</el-form-item>
									<el-form-item label="结束时间：" prop="end_time">
										<el-date-picker v-model="editForm.end_time" type="datetime" placeholder="请选择结束时间">
										</el-date-picker>
									</el-form-item>
									<el-form-item label="活动海报：" prop="invite_img">
										<uploadImage style="width: 30%;float: left;" :limit="1"
											v-model="editForm.invite_img"></uploadImage>
									</el-form-item>
								</el-form>
							</el-card>
							<div style="border-left: solid 1px #C6C6C6;padding-left: 40px;">
								<div style="font-size: 18px;padding-bottom: 20px;">活动模板</div>
								<div style="color:rgb(51,204,153);font-size:14px;margin-bottom: 30px;">
									* 您好，活动模板内容仅供活动设置参考，可根据目前运营地区实际情况进行设置或直接采用模板内容
								</div>
								<div class="templateBox" style="padding: 0 0 20px 0;border-bottom: solid 1px #e6e6e6;">
									<div>
											<i class="el-icon-location" style="color:rgb(51,204,153);font-size:16px"></i>
											阶段一：(目的：{{template.purpose}})
									</div>
									<p style="margin-top: 14px;"></p>
									<p>添加红包优惠：{{template.red}}</p>
									<p>新用户送零钱：{{template.bonus}}</p>
									<p>佣金设置：{{template.commission}}</p>
									<p style="margin-top: 14px;"></p>
									<p style="color:rgb(51,204,153);">预计拓客数：{{template.num}}</p>
									<p style="color:rgb(51,204,153);">预计活动投入资金：{{template.price}}</p>
								</div>
								<div class="templateBox" style="padding: 20px 0 20px 0;border-bottom: solid 1px #e6e6e6;">
									<div>
											<i class="el-icon-location" style="color:rgb(51,204,153);font-size:16px"></i>
											阶段二：(目的：{{template.purpose1}})
									</div>
									<p style="margin-top: 14px;"></p>
									<p>添加红包优惠：{{template.red1}}</p>
									<p>新用户送零钱：{{template.bonus1}}</p>
									<p>佣金设置：{{template.commission1}}</p>
									<p style="margin-top: 14px;"></p>
									<p style="color:rgb(51,204,153);">预计拓客数：{{template.num1}}</p>
									<p style="color:rgb(51,204,153);">预计活动投入资金：{{template.price1}}</p>
								</div>
								<div class="templateBox" style="padding: 20px 0 20px 0;">
									<div>
											<i class="el-icon-location" style="color:rgb(51,204,153);font-size:16px"></i>
											阶段三：(目的：{{template.purpose2}})
									</div>
									<p style="margin-top: 14px;"></p>
									<p>添加红包优惠：{{template.red2}}</p>
									<p>新用户送零钱：{{template.bonus2}}</p>
									<p>佣金设置：{{template.commission2}}</p>
									<p style="margin-top: 14px;"></p>
									<p style="color:rgb(51,204,153);">预计拓客数：{{template.num2}}</p>
									<p style="color:rgb(51,204,153);">预计活动投入资金：{{template.price2}}</p>
								</div>
							</div>
						</div>
						<div slot="footer">
							<el-button @click="dialogFormVisibleAddReturnActivity=false">取消</el-button>
							<el-button type="primary" @click="save()">保存</el-button>
						</div>
					</el-dialog>
				</el-card>
			</el-tab-pane>
			<el-tab-pane label="邀请记录" name="second">
				<el-card shadow="never">
					<!-- 搜索表单 -->
					<el-form :model="table1.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
						@keyup.enter.native="$refs.table1.reload()" @submit.native.prevent>
						<el-form-item label="邀请人类型:" label-width="90px">
							<el-select v-model="table1.where.type" placeholder="请选择" clearable class="input150">
								<!-- <el-option label="全部" :value="10" /> -->
								<el-option label="司机" :value="1" />
								<el-option label="跑腿" :value="2" />
								<!-- <el-option label="超市" :value="3" />
								<el-option label="叫餐" :value="4" /> -->
							</el-select>
						</el-form-item>
						<el-form-item label="邀请人姓名:" label-width="100px">
							<el-input v-model="table1.where.invite_name" placeholder="请输入邀请人姓名" class="drop-down-fourth"
								clearable />
						</el-form-item>
						<el-form-item label="邀请人手机号:" label-width="110px">
							<el-input v-model="table1.where.invite_phone" placeholder="请输入邀请人手机号"
								class="drop-down-fourth" clearable />
						</el-form-item>
						<!-- <el-form-item label="用户昵称:" label-width="80px">
							<el-input v-model="table1.where.user_name" placeholder="请输入用户昵称" class="drop-down-fourth"
								clearable />
						</el-form-item> -->
						<el-form-item label="用户手机号:" label-width="100px">
							<el-input v-model="table1.where.u_phone" placeholder="请输入用户手机号" class="drop-down-fourth"
								clearable />
						</el-form-item>
						<el-form-item label="省:" label-width="35px" class="w-150">
							<el-select v-model="table1.where.pid" @change="handleChangeProv(table1.where.pid)"
								placeholder='请选择省' clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" label-width="35px" class="w-150">
							<el-select v-model="table1.where.cid"
								@change="handleChangeCity(table1.where.cid),$forceUpdate()" placeholder='请选择市' clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="县/区:" label-width="62px" class="w-200">
							<el-select v-model="table1.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="开始时间:" label-width="80px">
							<el-date-picker v-model="table1.where.start_time" type="date" placeholder="搜索开始时间">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="结束时间:" label-width="80px">
							<el-date-picker v-model="table1.where.end_time" type="date" placeholder="搜索结束时间">
							</el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="$refs.table1.reload()" icon="el-icon-search"
								class="ele-btn-icon search-margin-left">搜索</el-button>
							<el-button @click="(table1.where={})&&$refs.table1.reload()">重置</el-button>

						</el-form-item>
					</el-form>
					<!-- 数据表格 -->
					<ele-data-table ref="table1" :config="table1" :choose.sync="choose" height="calc(100vh - 315px)"
						:stripe=true highlight-current-row>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="序号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="set_area" label="地区" show-overflow-tooltip min-width="220" />
							<el-table-column prop="type" label="邀请人类型" show-overflow-tooltip min-width="100" />
							<el-table-column prop="invite_name" label="邀请人姓名" show-overflow-tooltip min-width="100" />
							<el-table-column prop="invite_phone" label="邀请人手机号" show-overflow-tooltip min-width="120" />
							<el-table-column prop="user_name" label="被邀请用户昵称" show-overflow-tooltip min-width="140" />
							<el-table-column prop="u_phone" label="被邀请用户手机号" show-overflow-tooltip min-width="140" />
							<el-table-column prop="create_time" label="邀请时间" show-overflow-tooltip min-width="160">
								<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
							</el-table-column>
							<el-table-column prop="is_login" label="是否登录" show-overflow-tooltip>
								<template slot-scope="scope">
									<span>{{scope.row.is_login==0?'未登录':'已登录'}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="login_time" label="登录时间" show-overflow-tooltip min-width="160">
								<template slot-scope="{row}">{{ row.login_time*1000 | toDateString }}</template>
							</el-table-column>
							<el-table-column prop="startState" label="下单使用时间" show-overflow-tooltip min-width="160">
								<template slot-scope="{row}">{{ row.order_time}}</template>
							</el-table-column>
							<el-table-column prop="is_return" label="是否返佣" show-overflow-tooltip min-width="80">
								<template slot-scope="scope">
									<span>{{scope.row.is_return==0?'否':'是'}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="money" label="返佣金额(元)" show-overflow-tooltip min-width="120" />
						</template>
					</ele-data-table>
				</el-card>
			</el-tab-pane>
			<el-tab-pane label="邀请提现审核" name="third">
				<el-card shadow="never">
					<!-- 搜索表单 -->
					<el-form :model="table2.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
						@keyup.enter.native="$refs.table2.reload()" @submit.native.prevent>
						<el-form-item label="提现人类型:" label-width="90px" class="w-200">
							<el-select v-model="table2.where.type" placeholder="请选择" clearable class="ele-fluid">
								<!-- <el-option label="全部" :value="10" /> -->
								<el-option label="司机" :value="1" />
								<el-option label="跑腿" :value="2" />
								<!-- <el-option label="超市" :value="3" />
								<el-option label="叫餐" :value="4" /> -->
							</el-select>
						</el-form-item>
						<el-form-item label="提现人姓名:" label-width="100px">
							<el-input v-model="table2.where.invite_name" placeholder="请输入提现人姓名" class="drop-down-fourth"
								clearable />
						</el-form-item>
						<el-form-item label="提现人手机号:" label-width="110px">
							<el-input v-model="table2.where.driver_phone" placeholder="请输入提现人手机号"
								class="drop-down-fourth" clearable />
						</el-form-item>
						<!-- <el-form-item label="提现类型:" label-width="80px" style="width:220px">
							<el-select v-model="table2.where.payment" placeholder="全部" clearable class="ele-fluid">
								<el-option label="支付宝" value="1" />
								<el-option label="微信" value="2" />
							</el-select>
						</el-form-item> -->
						<el-form-item label="省:" label-width="35px" class="w-170">
							<el-select v-model="table2.where.pid" @change="handleChangeProv(table2.where.pid)"
								placeholder='请选择省' clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" label-width="35px" class="w-150">
							<el-select v-model="table2.where.cid"
								@change="handleChangeCity(table2.where.cid),$forceUpdate()" placeholder='请选择市' clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="县/区:" label-width="62px" class="w-200">
							<el-select v-model="table2.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="$refs.table2.reload()" icon="el-icon-search"
								class="ele-btn-icon search-margin-left">搜索</el-button>
							<el-button @click="(table2.where={})&&$refs.table2.reload()">重置</el-button>
							<download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出"
								url="finance/commissionLogExportExcel" :params="exportOrderParams2()" />
						</el-form-item>
					</el-form>

					<!-- 数据表格 -->
					<ele-data-table ref="table2" :config="table2" :choose.sync="choose2" height="calc(100vh - 315px)"
						:stripe=true highlight-current-row>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="序号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="invite_name" label="提现人姓名" show-overflow-tooltip min-width="120" />
							<el-table-column prop="set_area" label="所在地区" show-overflow-tooltip min-width="160" />
							<el-table-column prop="type" label="提现人类型" show-overflow-tooltip min-width="120" />
							<!--                        <el-table-column prop="type" label="提现人类型"  show-overflow-tooltip min-width="120">-->
							<!--                           {{type==1?'司机':type==2?'跑腿':type==3?'超市':'叫餐'}}-->
							<!--                        </el-table-column>-->
							<el-table-column prop="invite_phone" label="账号" show-overflow-tooltip min-width="120" />
							<el-table-column prop="money" label="提现金额(元)" show-overflow-tooltip min-width="140" />
							<el-table-column prop="application_time" label="提现申请时间" show-overflow-tooltip
								min-width="180">
								<template slot-scope="{row}">{{ row.application_time*1000 | toDateString }}</template>
							</el-table-column>
							<el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link icon="el-icon-success" type="primary" :underline="false"
										@click="with_success(row)">通过</el-link>
									<el-link icon="el-icon-error" type="danger" :underline="false"
										@click="with_error(row)">驳回</el-link>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-card>
			</el-tab-pane>
			<el-tab-pane label="邀请提现明细" name="fourth">
				<el-card shadow="never">
					<!-- 搜索表单 -->
					<el-form :model="table3.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
						@keyup.enter.native="$refs.table3.reload()" @submit.native.prevent>
						<el-form-item label="提现人类型:" label-width="90px" class="w-200">
							<el-select v-model="table3.where.type" placeholder="请选择" clearable>
								<!-- <el-option label="全部" :value="10" /> -->
								<el-option label="司机" :value="1" />
								<el-option label="跑腿" :value="2" />
							<!-- 	<el-option label="超市" :value="3" />
								<el-option label="叫餐" :value="4" /> -->
							</el-select>
						</el-form-item>
						<el-form-item label="提现人姓名:" label-width="100px">
							<el-input v-model="table3.where.invite_name" placeholder="请输入提现人姓名" class="drop-down-fourth"
								clearable />
						</el-form-item>
						<el-form-item label="提现人手机号:" label-width="110px">
							<el-input v-model="table3.where.invite_phone" placeholder="请输入手机号" class="drop-down-fourth"
								clearable />
						</el-form-item>
						<el-form-item label="提现类型:" label-width="80px">
							<el-select v-model="table3.where.pay_type" placeholder="全部" class="drop-down-third"
								clearable>
								<el-option label="微信" value="1" />
								<el-option label="支付宝" value="2" />
							</el-select>
						</el-form-item>
						<el-form-item label="省:" label-width="35px" class="w-170">
							<el-select v-model="table3.where.pid" @change="handleChangeProv(table3.where.pid)"
								placeholder='请选择省' clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" label-width="35px" class="w-150">
							<el-select v-model="table3.where.cid"
								@change="handleChangeCity(table3.where.cid),$forceUpdate()" placeholder='请选择市' clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="县/区:" label-width="62px" class="w-200">
							<el-select v-model="table3.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="$refs.table3.reload()" icon="el-icon-search"
								class="ele-btn-icon search-margin-left">搜索</el-button>
							<el-button @click="(table3.where={rebate: 2})&&$refs.table3.reload()">重置</el-button>
							<download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出"
								url="finance/commissionLogExportExcel" :params="exportOrderParams3()" />

						</el-form-item>
					</el-form>

					<!-- 数据表格 -->
					<ele-data-table ref="table3" :config="table3" :choose.sync="choose3" height="calc(100vh - 315px)"
						:stripe=true highlight-current-row>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="序号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="invite_name" label="提现人姓名" show-overflow-tooltip min-width="120" />
							<el-table-column prop="set_area" label="所在地区" show-overflow-tooltip min-width="220" />
							<el-table-column prop="type" label="提现人类型" show-overflow-tooltip min-width="120">
								<!--                {{type==1?'司机':type==2?'跑腿':type==3?'超市':'叫餐'}}-->
							</el-table-column>
							<el-table-column prop="invite_phone" label="账号" show-overflow-tooltip min-width="120" />
							<el-table-column prop="money" label="提现金额(元)" show-overflow-tooltip min-width="140" />
							<el-table-column prop="application_time" label="提现时间" show-overflow-tooltip min-width="120">
								<template slot-scope="{row}">{{ row.application_time*1000 | toDateString }}</template>
							</el-table-column>
							<!--              <el-table-column prop="status" label="状态"  show-overflow-tooltip min-width="120"/>-->
							<el-table-column prop="rebate_name" label="状态" show-overflow-tooltip>
								<template slot-scope="scope">
									<span>{{scope.row.rebate_name}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="pay_type" label="到账类型" show-overflow-tooltip min-width="120" />
						</template>
					</ele-data-table>
				</el-card>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import uploadImage from '@/components/uploadImage';
	import download from '@/components/Download/index'
	export default {
		name: "SysUser",
		data() {
			return {
				table: {
					url: '/finance/commission_list',
					where: {}
				},
				table1: {
					url: '/finance/commission_log',
					where: {}
				},
				table2: {
					url: '/finance/invite_with_log',
					where: {}
				},
				table3: {
					url: '/finance/invite_with_log',
					where: {
						rebate: 2
					}
				},
				choose: [], // 表格选中数据
				choose1: [], // 表格选中数据
				choose2: [], // 表格选中数据
				choose3: [], // 表格选中数据
				editList: {},
				editForm: {
					atype:2,
				}, // 表单数据
				checkForm: {},
				dialogTableVisibleCheck: false,
				dialogTableVisibleEdit: false,
				dialogTableVisibleUrgent: false,
				dialogFormVisibleAddReturnList: false,
				provArr: [],
				cityArr: [],
				districtArr: [],
				urgentList: [],
				handleNum: 1,
				imageUrl: false,
				headImg: '',
				activeName: 'first',
				dialogFormVisibleAddReturnActivity: false,
				row: [{
						state: '开启'
					},
					{
						state: '关闭'
					}
				],
				editRules: {
					pid: [{
						required: true,
						message: '请选择省份',
						trigger: "change"
					}],
					// atype: [{
					// 	required: true,
					// 	message: '请选择所属APP',
					// 	trigger: "change"
					// }],
					m_type: [{
						required: true,
						message: '请选择商户类型',
						trigger: "change"
					}],
					c_member_num: [{
						required: true,
						message: '请输入邀请人数',
						trigger: 'blur'
					}],
					c_order_num: [{
						required: true,
						message: '请输入免手续费订单数',
						trigger: 'blur'
					}],
					money: [{
						required: true,
						message: '请输入活动金额',
						trigger: 'blur'
					},{
						pattern: /^([0-9][0-9]*)+(.[0-9]{1,2})?$/, 
						message: '请输入大于0的有效数字', 
						trigger: 'blur'
					}],
					user_money: [{
						required: true,
						message: '请输入邀请用户获得佣金',
						trigger: 'blur'
					}],
					store_money: [{
						required: true,
						message: '请输入邀请商户获得佣金',
						trigger: 'blur'
					}],
					driver_money: [{
						required: true,
						message: '请输入邀请司机获得佣金',
						trigger: 'blur'
					}],
					start_time: [{
						type: 'date',
						required: true,
						message: '请选择开始时间',
						trigger: "change"
					}],
					end_time: [{
						type: 'date',
						required: true,
						message: '请选择结束时间',
						trigger: "change"
					}],
					invite_name: [{
						required: true,
						message: '请输入活动海报名称',
						trigger: 'blur'
					}],
					invite_img: [{
						required: true,
						message: '请选择活动海报',
						trigger: 'blur'
					}],
					invite_detail: [{
						required: true,
						message: '请输入活动海报描述',
						trigger: 'blur'
					}],
				},
				template:[]
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})
			this.$http.get('/finance/activity_template').then(res=>{
				this.template = res.data.data
			})
		},

		computed: {
			...mapGetters(["permission"]),
		},
		components: {
			uploadImage,
			download
		},
		mounted() {},
		methods: {
			exportOrderParams2() {
				const query = this.table2.where
				// const params = this.params
				const selectionIds = this.choose2.map(item => {
					return item.id
				})

				return {
					ids: selectionIds,
					...query
				}
			},
			exportOrderParams3() {
				const query = this.table3.where
				// const params = this.params
				const selectionIds = this.choose3.map(item => {
					return item.id
				})

				return {
					ids: selectionIds,
					...query
				}
			},
			handleWatch(row) {
				this.editList = row
				this.dialogFormVisibleAddReturnList = true
			},
			handleClick(e) {
				//console.log(e)
			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					if (this.handleNum == 1) {
						this.table.where.cid = ''
						this.table.where.aid = ''
					} else if (this.handleNum == 2) {
						this.table1.where.cid = ''
						this.table1.where.aid = ''
					} else if (this.handleNum == 3) {
						this.table2.where.cid = ''
						this.table2.where.aid = ''
					} else if (this.handleNum == 4) {
						this.table3.where.cid = ''
						this.table3.where.aid = ''
					}
					if (this.dialogFormVisibleAddReturnActivity) {
						this.editForm.cid = ''
						this.editForm.aid = ''
					}
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					if (this.handleNum == 1) {
						this.table.where.aid = ''
					} else if (this.handleNum == 2) {
						this.table1.where.aid = ''
					} else if (this.handleNum == 3) {
						this.table2.where.aid = ''
					} else if (this.handleNum == 4) {
						this.table3.where.aid = ''
					}
					if (this.dialogFormVisibleAddReturnActivity) {
						this.editForm.aid = ''
					}
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
					if (this.dialogFormVisibleAddReturnActivity) {
						this.editForm.pid = ''
						this.editForm.aid = ''
					}
				}
			},
			pause_start(row) {
				this.$confirm('确定暂停活动吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/finance/return_money_switch', {
						id: row.id,
						zt: 0
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: '暂停成功'
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			switch_start(row) {
				this.$confirm('确定开启活动吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/finance/return_money_switch', {
						id: row.id,
						zt: 1
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: '开启成功'
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			stop_start(row) {
				this.$confirm('确定关闭活动吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/finance/return_money_switch', {
						id: row.id,
						zt: 2
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: '关闭成功'
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			//  保存邀请返现活动
			save() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/finance/save_returnMoney', this.editForm).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.showEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg,
									// duration:0,
									// showClose:true
								});
								this.$refs.table.reload();
								this.dialogFormVisibleAddReturnActivity = false
							} else {
								this.$message.error({
									message:res.data.msg,
									// duration:0,
									// showClose:true
								});
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
							return false;
						}
					});
			},
			//申请驳回操作
			with_error(row) {
				this.$confirm('确定要驳回该申请吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/finance/check_invite_status', {
						id: row.id,
						rebate: 3
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table2.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			// 提现申请审核通过
			with_success(row) {
				this.$confirm('确定要通过该申请吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/finance/check_invite_status', {
						id: row.id,
						rebate: 2
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table2.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
		},
		watch: {
			'activeName': function(val) { //监听切换状态-计划单
				//console.log(val)
				if (val == 'first') {
					this.handleNum = 1
				} else if (val == 'second') {
					this.handleNum = 2
				} else if (val == 'third') {
					this.handleNum = 3
				} else if (val == 'fourth') {
					this.handleNum = 4
				}
			}
		},
	}
</script>

<style scoped>
	/* .edit /deep/.el-form-item__content {
		margin-left: 0 !important;
	} */

	.ele-block .el-upload-dragger {
		width: 100%;
	}

	.changeStyle {
		width: 150px;
		margin-right: 30px;
	}

	.ele-body {
		padding: 15px;
	}

	.el-upload-list el-upload-list--text {
		display: none !important
	}

	/deep/.el-tabs__item {
		padding: 0 20px !important;
		font-size: 15px;
	}

	/deep/.el-tabs__nav-scroll {
		background: white !important;
		padding-top: 10px;
	}

	.boxTitle {
		padding: 8px
	}

	.orderImgBox {
		margin-left: 10px;
		height: 80px;
	}

	.orderImgBox span {
		font-size: 36px;
	}

	.user-info-tabs>>>.el-tabs__nav-wrap {
		padding-left: 20px;
	}

	.innerBox {
		width: 60%;
		margin: 0 auto;
		padding-top: 20px;
		border-bottom: 1px rgb(240, 240, 240) solid;
	}

	.innerBoxTo {
		width: 60%;
		margin: 0 auto;
		padding-top: 20px;
	}

	.innerTitle {
		font-size: 16px;
		margin-bottom: 20px;
		margin-left: 13px;
	}

	.inputWidth {
		width: 100%;
	}
	.templateBox p{
		padding: 4px 0 0 0;
	}
	/deep/.el-dialog{
		margin-top: 60px !important;
	}
</style>
